"use client";

export const Hamburger = ({
  onClick,
}: Readonly<{ onClick: () => void }>): JSX.Element => (
  <div className="absolute right-0 top-0 order-last flex h-full lg:hidden">
    <button
      className="focus:ring-green inline-flex items-center justify-center rounded-lg bg-transparent p-3 focus:outline-none focus:ring-2 focus:ring-inset"
      data-testid="nav::mobile-open"
      name="Open menu"
      onClick={onClick}
      type="button"
    >
      <span className="sr-only">Open menu</span>
      {/* Heroicon name: menu */}
      <svg
        aria-hidden="true"
        className="h-6 w-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 6h16M4 12h16M4 18h16"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </svg>
    </button>
  </div>
);
