"use client";

import Image from "next/image";
import { Transition } from "@headlessui/react";
import type { PropsWithChildren } from "react";

export const MobileNavPopover = ({
  isOpen,
  onClick,
  children,
}: Readonly<
  PropsWithChildren<{ onClick: () => void; isOpen: boolean }>
>): JSX.Element => (
  <Transition
    enter="duration-200 ease-out"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
    show={isOpen}
  >
    <div className="absolute inset-x-0 top-0 origin-top-right p-1 transition lg:hidden">
      <div className="rounded-nav bg-white shadow-xl ring-black">
        <div className="py-1">
          <div className="flex justify-between px-1">
            <div>
              <Image
                alt="Highnote"
                className=""
                height={56}
                src="/img/highnote.svg"
                width={162}
              />
            </div>
            <div className="-mr-2 p-2">
              <button
                className="focus:ring-green inline-flex items-center justify-center rounded-lg bg-white px-3 py-2 focus:outline-none focus:ring-2 focus:ring-inset"
                onClick={onClick}
                type="button"
              >
                <span className="sr-only">Close menu</span>
                {/* Heroicon name: x */}
                <svg
                  aria-hidden="true"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 18L18 6M6 6l12 12"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                  />
                </svg>
              </button>
            </div>
          </div>
          {children === undefined ? undefined : (
            <div className="mt-1" data-testid="mobileNav::container">
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  </Transition>
);
