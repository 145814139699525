"use client";

import type { PropsWithChildren } from "react";

const BASE_CLASS = "z-20 relative w-full";

export const NavContainer = ({
  children,
  backgroundClass,
  onClick,
}: Readonly<
  PropsWithChildren<{
    backgroundClass?: string;
    onClick?: () => void;
  }>
>): JSX.Element => (
  <nav
    className={`${BASE_CLASS} ${
      backgroundClass ?? "bg-bone mx-auto max-w-screen-2xl lg:fixed"
    }`}
    onClick={onClick}
  >
    {children}
  </nav>
);
